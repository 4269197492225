import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-theme-material-ui';
import MuiCard from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 20,
        textAlign: 'left',
        margin: 20,
        padding: 20,
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
    },
    headerRoot: {
        padding: 0,
        paddingLeft: 20,
    },
    headerTitle: {
        fontFamily: 'Alef',
        fontSize: '2rem',
        fontWeight: 400,
        textAlign: 'left',
        color: theme.palette.primary.main
    },
    contentContainer: {
        paddingTop: 0,
    },
    contentText: {
        fontSize: '1.2rem',
        fontWeight: 300,
        letterSpacing: '0.09em',
    },
    button: {
        margin: theme.spacing(1),
        padding: '15px 20px',
        fontWeight: 'bold',
        borderRadius: 8,
        color: theme.palette.secondary.main,
        minWidth: 'max-content',

        '&:hover': {
            color: 'white',
            textDecoration: 'none',
            backgroundColor: theme.palette.tertiary.main,
        },

        '&:focus': {
            color: 'white',
            backgroundColor: theme.palette.tertiary.main,
        }
    },
    action: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'end',
        flexGrow: 1,
    },
}));

const Card = ({
    title,
    titleComponent,
    body,
    linkLabel,
    linkTo,
    linkTarget
}) => {
    const classes = useStyles();

    return (
        <MuiCard className={classes.root}>
            <CardHeader
                title={title}
                component={titleComponent}
                classes={{
                    root: classes.headerRoot,
                    title: classes.headerTitle,
                }}
            />
            <CardContent className={classes.contentContainer}>
                <Typography
                    variant="body1"
                    className={classes.contentText}
                >
                    {body}
                </Typography>
            </CardContent>
            <CardActions className={classes.action}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    href={linkTo}
                    target={linkTarget}
                >
                    {linkLabel}
                </Button>
            </CardActions>
        </MuiCard>
    );
};

Card.defaultProps = {
    titleComponent: 'div',
    linkTarget: null,
};

Card.propTypes = {
    title: PropTypes.string.isRequired,
    titleComponent: PropTypes.string,
    body: PropTypes.string.isRequired,
    linkLabel: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
    linkTarget: PropTypes.string,
};

export default Card;


