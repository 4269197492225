import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import TitleLogo from '../../content/assets/isolated-monochrome-black.svg';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Card from '../components/card';

const useStyles = makeStyles(theme => ({
    root: {

    },
    hidden: {
        position: 'absolute',
        left: '-10000px',
        top: 'auto',
        width: 1,
        height: 1,
        overflow: 'hidden'
    },
    heroContainer: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        borderBottomLeftRadius: 150,
        height: 300,
        width: '100vw',
        display: 'flex',
        justifyContent: 'start',
    },
    profilePicWrapper: {
        height: 300,
        minWidth: 370,
        overflowY: 'hidden',
        borderBottomLeftRadius: 150,

        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            left: -175,
            top: 0,
            overflow: 'hidden',
        },
    },
    introWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: 20,
        paddingLeft: 100,
        width: '100%',

        [theme.breakpoints.down('md')]: {
            paddingLeft: 50,
        },

        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end',
            marginLeft: -50,
            paddingRight: 10,
        },

        [theme.breakpoints.down('xs')]: {
            marginLeft: 100,
            paddingRight: 10,
            paddingTop: 0,
        },
    },
    introLogo: {
        maxHeight: 83,

        [theme.breakpoints.down('md')]: {
            minHeight: 74,
            maxHeight: 74,
            marginBottom: 10,
        },

        [theme.breakpoints.down('sm')]: {
            minHeight: 46,
            maxHeight: 74,
            marginBottom: 10,
        },

        [theme.breakpoints.down('xs')]: {
            // minHeight: 46,
            maxHeight: 46,
            marginTop: 10,
            marginBottom: 10,
        },
    },
    introTitle: {
        fontFamily: 'Alef',
        fontSize: '2.7rem',
        fontWeight: 500,
        textAlign: 'left',
        lineHeight: '1.8em',
        letterSpacing: '0.08em',

        [theme.breakpoints.down('md')]: {
            fontSize: '2rem',
            lineHeight: '1.2em',
            paddingBottom: 10,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8rem',
            textAlign: 'right',
        },
    },
    introText: {
        fontSize: '1.4rem',
        fontWeight: 400,
        textAlign: 'left',
        lineHeight: '1.5em',
        letterSpacing: '0.09em',
        color: theme.palette.secondary.main,

        [theme.breakpoints.down('sm')]: {
            // fontSize: '1.2rem',
            // textAlign: 'right',
            // lineHeight: '1.4em',
            // letterSpacing: '0.06em',
            display: 'none',
        },

        // [theme.breakpoints.down('xs')]: {
        //     fontSize: '1.1rem',
        //     letterSpacing: '0.04em',
        // },
    },
    cardsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexFlow: 'row wrap',
        padding: '40px 30px 0',
    },
    card: {
        flexBasis: 0,
        flexGrow: 1,
    }
}));

const Home = ({ data, location }) => {
    const rootPath = `${__PATH_PREFIX__}/`;
    const classes = useStyles();
    const { title, author } = data.site.siteMetadata;
    const image = getImage(data.avatar.childImageSharp.gatsbyImageData);

    return (
        <Layout location={location} title={title}>
            <Seo title={title} />
            <div className={classes.root}>
                <div className={classes.heroContainer}>
                    <div className={classes.profilePicWrapper}>
                        <GatsbyImage alt="" image={image} />
                    </div>
                    <div className={classes.introWrapper}>
                        <TitleLogo alt="" className={classes.introLogo} />
                        <Typography variant="h1" className={classes.hidden}>{author.name}</Typography>
                        <Typography variant="h2" className={classes.introTitle}>{author.jobTitle}.</Typography>
                        <Typography variant="body1" className={classes.introText}>{author.summaryLine1}.</Typography>
                        {/* <Typography variant="body1" className={classes.introText}>{author.summaryLine2}.</Typography> */}
                    </div>
                </div>
                <div className={classes.cardsContainer}>
                    <div className={classes.card}>
                        <Card
                            title="Blog."
                            titleComponent="h3"
                            body="Thoughts, musings and lessons learned on a variety of topics in my day to day work as a SRE/Platform Engineer and formerly Fullstack Developer."
                            linkLabel="SEE BLOG POSTS"
                            linkTo={`${rootPath}blog`}
                        />
                    </div>
                    <div className={classes.card}>
                        <Card
                            title="Works."
                            titleComponent="h3"
                            body="I like to talk and write about Engineering Culture, Platform/DevOps/SRE, DevEx, Tooling, Front-End Development, and Web Accessibility! You can view links to all the talks, podcasts, and articles I've worked on here."
                            linkLabel="SEE WORKS"
                            linkTo={`${rootPath}works`}
                        />
                    </div>
                    <div className={classes.card}>
                        <Card
                            title="Egghead Courses."
                            titleComponent="h3"
                            body="I'm an egghead.io instructor!  Egghead.io is an awesome platform for online learning about all the latest topics in Web Development."
                            linkLabel="SEE COURSES"
                            linkTo="https://egghead.io/instructors/erin-doyle"
                            linkTarget="_blank"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Home;

export const pageQuery = graphql`{
  avatar: file(absolutePath: {regex: "/blue_hair_headshot.png/"}) {
    childImageSharp {
      gatsbyImageData(width: 370, height: 370, placeholder: TRACED_SVG, layout: FIXED)
    }
  }
  site {
    siteMetadata {
      author {
        name
        jobTitle
        summaryLine1
        summaryLine2
      }
      title
    }
  }
}
`
